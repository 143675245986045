import React, { RefObject, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as d3 from 'd3';
import { Tab, Tabs } from '@mui/material';
import { Map } from 'immutable';

import { CallCountData } from './customtypes';
import { Row } from './CallTranscriptionsComponent';

interface TabPanelProps {
  index: number;
  value: { start_dt: string; ct: string[] };
  tabIndex: number;
}

interface SummaryTabPanelProps {
  children?: string;
  index: number;
  value: { start_dt: string; ct: string[] }[];
  tabIndex: number;
}

type CallCountComponentProps = {
  callCountData: CallCountData[];
};

const GetCallsTabPanel = (props: TabPanelProps) => {
  const { value, index, tabIndex } = props;
  return (
    <div hidden={index !== tabIndex}>
      <h1>Calls for {value.start_dt}</h1>
      {value.ct.map((d) => (
        <Row
          row={{
            contact_id: d,
            connected_date: value.start_dt,
            address: '',
            connected_hour: 0,
            connected_minute: 0,
            connected_second: 0,
            disconnected_time_stamp: '',
            history: [],
          }}
        />
      ))}
    </div>
  );
};

const GetSummaryTabPanel = (props: SummaryTabPanelProps) => {
  const { value, index, tabIndex, children } = props;
  const dataForTable = value.map((d: { start_dt: string; ct: string[] }) => ({
    start_dt: d.start_dt,
    ct: d.ct.length,
  }));

  const columns: GridColDef[] = [
    { field: 'start_dt', headerName: 'Call Date', width: 150 },
    { field: 'ct', headerName: 'Count', width: 130 },
  ];

  return (
    <div
      style={{ height: 400, width: '100%' }}
      hidden={index !== 0}
      role="tabpanel"
    >
      {tabIndex === 0 && children}
      {tabIndex === 0 && (
        <DataGrid
          rows={dataForTable}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          getRowId={(data) => data.start_dt}
        />
      )}
    </div>
  );
};

export const CallCountComponent: React.FunctionComponent<
  CallCountComponentProps
> = (props) => {
  const { callCountData } = props;
  // const svg:any;
  const margin = 50;
  const width = 750 - margin * 2;
  const height = 400 - margin * 2;
  const svgRef: RefObject<SVGSVGElement> = React.useRef<SVGSVGElement>(null);
  const [dataTabs, setDataTabs] = React.useState(Map<string, string[]>());
  const [tabIndex, setTabIndex] = React.useState(0);
  useEffect(() => {
    createSvg();
  }, [dataTabs, tabIndex]);

  const pushToCallDataTabs = (
    date: string,
    data: { start_dt: string; ct: string[] }
  ) => {
    setDataTabs(dataTabs.set(data.start_dt, data.ct));
  };

  const createSvg = () => {
    d3.select('#callcountdiv').selectAll('*').remove();
    //     // const svgElement: SvgInHtml = document.createElement('svg') as SvgInHtml;

    const svg = d3
      .select('#callcountdiv')
      .append('svg')
      .attr('id', 'callcountsvg')
      .attr('width', width + margin * 2)
      .attr('height', height + margin * 2)
      .append('g')
      .attr('transform', `translate(${margin}, ${margin})`);
    drawBars(svg, callCountData);
  };

  const drawBars: (
    svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    callCountData: CallCountData[]
  ) => void = (
    svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    callCountData: CallCountData[]
  ) => {
    // Create the X-axis band scale
    // console.log(this.svg)
    const data: CallCountData[] = callCountData;
    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(data.map((d) => d.start_dt))
      .padding(0.2);

    const xAxis = d3.axisBottom(x).tickFormat((d) => {
      return d.split('T')[0];
    });

    //     // Draw the X-axis on the DOM
    svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis)
      .selectAll('text')
      .attr('transform', 'translate(-10,0)rotate(-45)')
      .style('text-anchor', 'end');

    // Create the Y-axis band scale
    const maxCt = Math.max(...data.map((d) => d.ct.length));
    const y = d3.scaleLinear().domain([0, maxCt]).range([height, 0]);

    //     // Draw the Y-axis on the DOM
    svg.append('g').call(d3.axisLeft(y));

    //     // Create and fill the bars
    svg
      .selectAll('bars')
      .data(data)
      .enter()
      .append('rect')
      .classed('rect_class', true)
      .on('click', function dataCall(e, data) {
        pushToCallDataTabs(data.start_dt!, data);
      })
      .attr('x', (d: CallCountData) => x(d.start_dt)!)
      .attr('y', (d: CallCountData) => y(d.ct.length))
      .attr('width', x.bandwidth())
      .attr('height', (d: CallCountData) => height - y(d.ct.length));
  };

  // drawBars(callCountData)

  return (
    <>
      <div id="callcountdiv">
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        <svg ref={(ref: SVGSVGElement) => svgRef.current} />
      </div>
      <Tabs value={callCountData}>
        <Tab
          label="Data by Date"
          tabIndex={0}
          value={callCountData}
          key="summary"
          onClick={() => setTabIndex(0)}
        />
        {dataTabs.toArray().map((k, i) => (
          <Tab
            label={k[0]}
            tabIndex={tabIndex}
            value={i + 1}
            key={k[0]}
            onClick={() => setTabIndex(i + 1)}
          />
        ))}
      </Tabs>
      <GetSummaryTabPanel value={callCountData} index={0} tabIndex={tabIndex}>
        Summary by date
      </GetSummaryTabPanel>
      {dataTabs.toArray().map((k, i) => (
        <GetCallsTabPanel
          value={{ start_dt: k[0], ct: k[1] }}
          tabIndex={tabIndex}
          index={i + 1}
          key={`calls-${k[0]}`}
        />
      ))}
    </>
  );
};
