import React, { RefObject, useState, useEffect } from 'react';
// import { GridColDef } from '@mui/x-data-grid';
import * as d3 from 'd3';
import {
  AppBar,
  Button,
  Card,
  CircularProgress,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Close as CloseIcon } from '@mui/icons-material';

import DashboardWidgetProps from './types/DashboardWidget';
import { groupBy } from './utils';
import { Row } from './CallTranscriptionsComponent';

export type BucketDataType = {
  key: number;
  contact_ids: string[];
};

export type BucketData = {
  key: number;
  value: number;
  contact_id: string;
};

type BucketType = {
  [key: number]: BucketData[];
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<HTMLDivElement>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ConversationDurationComponent: React.FC<DashboardWidgetProps> = ({
  daysDiff,
  endDate,
  className,
}) => {
  const margin = 50;
  const svgRef: RefObject<SVGSVGElement> = React.useRef<SVGSVGElement>(null);
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [bucketData, setBucketData] = React.useState<BucketDataType>();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    type KeyMapType = { [key: number]: string };
    const keyMap: KeyMapType = {
      1: '< 30',
      2: '30-60',
      3: '60-90',
      4: '90-120',
      5: '120-150',
      6: '150-180',
      7: '>180',
    };
    const svgContainer = d3.select('#calldurationdiv');
    const width =
      (svgContainer!.node()! as Element).getBoundingClientRect().width -
      margin * 2;
    const height = 400 - margin * 2;
    type DurationData = {
      key: number;
      value: number;
      contact_ids: string[];
    };
    const drawBars: (
      svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
      data: DurationData[]
    ) => void = (
      svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
      data: DurationData[]
    ) => {
      const y = d3
        .scaleBand()
        .range([height, 1])
        .domain(Object.keys(keyMap))
        .padding(0.2);

      const x = d3
        .scaleLinear()
        .domain([Math.max(...data.map((d) => d.value)), 0])
        .range([width, 0]);

      svg
        .append('g')
        .attr('transform', `translate(0, ${height})`)
        .style('text-anchor', 'end')
        .call(d3.axisBottom(x));

      svg
        .append('g')
        .call(d3.axisLeft(y).tickFormat((d) => keyMap[parseInt(d, 10)]));

      svg
        .selectAll('bars')
        .data(data)
        .enter()
        .append('rect')
        .classed('rect_class', true)
        .on('click', function dataCall(e, data) {
          setBucketData(data);
          handleClickOpen();
        })
        .attr('x', 0)
        .attr('y', (d: DurationData) =>
          y(d.key.toString()) !== undefined ? y(d.key.toString())! : null
        )
        .attr('height', y.bandwidth())
        .attr('width', (d: DurationData) => x(d.value ? d.value : 0))
        .attr('fill', '#62a0a5');
    };

    const createSvg = (data: DurationData[]) => {
      d3.select('#calldurationdiv').selectAll('svg')?.remove();

      const svg = d3
        .select('#calldurationdiv')
        .append('svg')
        .attr('id', 'calldurationsvg')
        .attr('width', width + margin * 2)
        .attr('height', height + margin * 2)
        .append('g')
        .attr('transform', `translate(${margin}, ${margin})`);
      drawBars(svg, data);
    };
    if (daysDiff) {
      d3.select('#calldurationdiv').selectAll('svg')?.remove();
      setFetching(true);
      fetch(
        `${process.env.REACT_APP_DASHBOARD_API_URL}dashboard/callduration/${daysDiff}?end_dt=${endDate}`
      )
        .then((response) => response.json())
        .then((data) => {
          setFetching(false);
          const totalCalls = data.length;
          const groupedByBucket: BucketType = groupBy(data, 'bucket');
          const dataSummaryTemp = Object.keys(groupedByBucket).map((key) => ({
            key: parseInt(key, 10),
            value:
              ((groupedByBucket[parseInt(key, 10)].length * 1.0) / totalCalls) *
              100,
            contact_ids: groupedByBucket[parseInt(key, 10)].map(
              (v) => v.contact_id
            ),
          }));
          createSvg(dataSummaryTemp);
        })
        .catch((e) => console.log(e));
    }
  }, [daysDiff]);
  return (
    <Card
      title="Conversations Duration"
      className={className && className}
      variant="outlined"
    >
      <div>
        <div style={{ display: 'grid', alignItems: 'normal' }}>
          <div>Conversations Duration</div>
        </div>
      </div>
      <div id="calldurationdiv">
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        <svg ref={(ref: SVGSVGElement) => svgRef.current} />
        {fetching && (
          <div>
            <CircularProgress style={{ color: '#62a0a5' }} />
          </div>
        )}
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} style={{ background: '#62a0a5' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Converstation details
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <div>
          <h1>Calls for bucket # {bucketData?.key}</h1>
          {bucketData?.contact_ids?.map((d) => (
            <Row
              key={d}
              row={{
                contact_id: d,
                connected_date: '',
                address: '',
                connected_hour: 0,
                connected_minute: 0,
                connected_second: 0,
                disconnected_time_stamp: '',
                history: [],
              }}
            />
          ))}
        </div>
      </Dialog>
    </Card>
  );
};
