import React, { useEffect, useState, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Card, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { debounce } from 'lodash';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import SearchBar from './components/SearchBar';
import FilterDialog from './components/FilterDialog';
import { CallSummary } from './customtypes';
import './CallTranscriptionsComponent.css';
import DashboardWidgetProps from './types/DashboardWidget';

type SearchBarType = {
  searchTerm: string;
};

const COLUMNS = ['Contact ID', 'Contact Number', 'Date', 'Timestamp', 'Intent'];

export const Row = (props: { row: CallSummary }) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    setFetching(true);
    fetch(
      `${process.env.REACT_APP_DASHBOARD_API_URL}/dashboard/transcript/${row.contact_id}`
    )
      .then((response) => response.json())
      .then((data) => {
        row.history = data;
        setFetching(false);
      })
      .catch((e) => console.log(e));
  }, [row]);
  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        className="row__heading"
        data-row-open={open}
      >
        <TableCell
          rowSpan={open ? 2 : 1}
          style={{
            verticalAlign: 'top',
            borderBottom: open ? 'none' : 'unset',
            minWidth: '65px',
            width: '65px',
            maxWidth: '65px',
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell className="row__header-title" component="th" scope="row">
          {row.contact_id}
        </TableCell>
        <TableCell className="row__header-title">{row.address}</TableCell>
        <TableCell className="row__header-title">
          {row.connected_date.split('T')[0]}
        </TableCell>
        {row.connected_hour ? (
          <TableCell className="row__header-title">{`${row.connected_hour}:${row.connected_minute}:${row.connected_second}`}</TableCell>
        ) : (
          <TableCell className="row__header-title" />
        )}
        <TableCell className="row__header-title">Expand to View</TableCell>
      </TableRow>
      <TableRow className="row__collapsible-section" data-row-open={open}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          {open && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="medium" aria-label="purchases">
                  <TableHead>
                    <TableRow className="row__subheading">
                      <TableCell>Utterance</TableCell>
                      <TableCell>Intent</TableCell>
                      <TableCell>Confidence</TableCell>
                      <TableCell>Audio</TableCell>
                    </TableRow>
                  </TableHead>
                  {!fetching && (
                    <TableBody>
                      {row.history?.map((historyRow) => (
                        <TableRow key={`${row.contact_id}-history`}>
                          <TableCell>{historyRow.utterance}</TableCell>
                          <TableCell>{historyRow.primaryintent}</TableCell>
                          <TableCell>
                            {historyRow.transcriptionconfidence}
                          </TableCell>
                          <TableCell>
                            {historyRow.s3path?.length > 5 &&
                            historyRow.idx === 1 ? (
                              // eslint-disable-next-line jsx-a11y/media-has-caption
                              <audio controls>
                                <source
                                  src={`${
                                    process.env.REACT_APP_DASHBOARD_API_URL
                                  }dashboard/stream?url=${encodeURIComponent(
                                    historyRow.s3path
                                  )}`}
                                  type="audio/wav"
                                />
                              </audio>
                            ) : (
                              'NA'
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                  {fetching && <CircularProgress />}
                </Table>
              </Box>
            </Collapse>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export const CallTranscriptionsComponent: React.FunctionComponent<
  DashboardWidgetProps
> = ({ daysDiff, endDate }) => {
  const [callTranscriptSummary, setCallTranscriptSummary] = useState<
    CallSummary[]
  >([]);
  const [filteredCallTranscriptSummary, setFilteredCallTranscriptSummary] =
    useState<CallSummary[]>([]);

  const { register, watch } = useForm<SearchBarType>({
    defaultValues: {
      searchTerm: '',
    },
  });

  const searchTerm = watch('searchTerm');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const searchCategories: (keyof CallSummary)[] = ['contact_id', 'address'];
  const debouncedSearchTermOnChange = useCallback(
    debounce((searchTerm) => setDebouncedSearchTerm(searchTerm), 300),
    []
  );
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (daysDiff) {
      // axios.get(`${process.env.REACT_APP_DASHBOARD_API_URL}dashboard/dailyreturninguserscount/${daysDiff}`,{timeout:120000})
      fetch(
        `${process.env.REACT_APP_DASHBOARD_API_URL}dashboard/callsummary/${daysDiff}/1?end_dt=${endDate}`
      )
        .then((response) => response.json())
        .then((data) => {
          setCallTranscriptSummary(data);
        })
        .catch((e) => console.log(e));
    }
  }, [daysDiff]);

  useEffect(() => {
    if (callTranscriptSummary.length > 0) {
      const preFilteredCalls = callTranscriptSummary;
      const searchFilteredCalls = getSearchedCalls(preFilteredCalls);
      setFilteredCallTranscriptSummary(searchFilteredCalls);
    }
  }, [callTranscriptSummary, debouncedSearchTerm]);

  useEffect(() => {
    debouncedSearchTermOnChange(searchTerm);
  }, [searchTerm, debouncedSearchTermOnChange]);

  const getSearchedCalls = (filteredCalls: CallSummary[]): CallSummary[] => {
    const searchTerm = debouncedSearchTerm;
    if (searchTerm && searchTerm !== '') {
      const searchFilterdCalls = filteredCalls.filter((call) =>
        searchCategories.some((category) =>
          call[category]
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      );
      return searchFilterdCalls;
    }
    return filteredCalls;
  };

  return (
    <Card className="p-3">
      <div className="callTranscription__app-bar">
        <div className="callTranscription__app-bar-title">
          Call Transcriptions
        </div>
        <div className="callTranscription__app-bar-widgets">
          <Stack spacing={3} direction="row">
            <Button
              variant="text"
              color="primary"
              sx={{
                textTransform: 'none',
                color: 'var(--DK-GRAY)',
                fontSize: '1rem',
              }}
              // eslint-disable-next-line consistent-return
              onClick={() => {
                if (dialogRef.current && dialogRef.current.open === false) {
                  dialogRef.current.show();
                  return;
                }

                if (dialogRef.current && dialogRef.current.open === true) {
                  dialogRef.current.close();
                }
              }}
              disabled={filteredCallTranscriptSummary.length === 0}
            >
              <FilterAltIcon sx={{ marginRight: '5px' }} />
              Filters
            </Button>
            <SearchBar
              {...register('searchTerm')}
              label="Search"
              role="searchbox"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </div>
        <FilterDialog
          calls={callTranscriptSummary}
          filteredCalls={filteredCallTranscriptSummary}
          setFilteredCalls={setFilteredCallTranscriptSummary}
          dialogRef={dialogRef}
        />
      </div>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className="callTranscriptionComponent">
              <TableCell />
              {COLUMNS.map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCallTranscriptSummary.map((row) => (
              <Row key={row.contact_id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
