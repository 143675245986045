import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

import DashboardWidgetProps from './types/DashboardWidget';

export const WordCloudComponent: React.FunctionComponent<
  DashboardWidgetProps
> = (props) => {
  const { daysDiff, endDate } = props;
  const [blobURL, setBlobURL] = useState<string>('');
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    if (daysDiff) {
      setFetching(true);
      fetch(
        `${process.env.REACT_APP_DASHBOARD_API_URL}dashboard/wordcloud/${daysDiff}?end_dt=${endDate}`
      )
        .then((response) => response.blob())
        .then((blob) => {
          setBlobURL(URL.createObjectURL(blob));
          setFetching(false);
        })
        .catch((e) => console.log(e));
    }
  }, [daysDiff]);

  return (
    <>
      {blobURL !== '' && <img src={blobURL} id="myImg" alt="Word Cloud" />}
      {fetching && (
        <div>
          <CircularProgress style={{ color: '#62a0a5' }} />
        </div>
      )}
    </>
  );
};
